import React from 'react';
import { Box, Text } from 'theme-ui';


import Link from '../components/Link';
import Layout from '../components/Layout/Layout';
import Image from '../components/Image';
import SEO from '../components/SEO';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Box mb={2}>
      <Text as="h1">Hi people</Text>
      <Text>Welcome to your new Gatsby site.</Text>
      <Text>Now go build something great.</Text>
    </Box>
    <Box sx={{ maxWidth: 300 }} p="1.45rem" m="0 auto">
      <Image src="gatsby-astronaut.png" />
    </Box>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
);

export default IndexPage;
